import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: {} || ''
    },
    getters: {
        getToken(state, getters) {
            return state.token
        }
    },
    mutations: {
        saveToken(state, value) {
            state.token = value
        },

    },

    actions: {
    },
    modules: {
    }
})
