
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class sliderBar extends Vue {
    private list: any = [
        {
            id: 1,
            text: "投票设置",
            // url: "@/assets/user/vote.png",
            router: "main-page",
        },
        {
            id: 2,
            text: "选手设置",
            // url: "@/assets/user/vote.png",
            router: "player-settings",
        },
        {
            id: 3,
            text: "抽查奖置",
            // url: "/main-page",
            router: "lottery-settings",
        },
        {
            id: 4,
            text: "数据统计",
            // url: "/main-page",
            router: "data-statistics",
        },
    ];
    private current: number = 0;

    mounted() {
        if (this.$route.name == this.list[this.current].router) return;
        let index = this.list.findIndex(
            (v: any) => v.router == this.$route.name
        );
        if (index == -1) {
            index = 0;
        }
        this.current = index;
        // this.$router.push({ name: this.list[this.current].router });
    }

    private switchClick(item: any, index: number) {
        if (this.current == index) return;
        this.current = index;
        this.$router.push({ name: item.router });
    }

    public get key() {
        return this.$route.path;
    }
}
