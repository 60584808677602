import router from "./router";
import { Route } from "vue-router";
import { Message } from "element-ui";
import store from './store'

router.beforeEach((to: Route, _: Route, next: any) => {
    if (to.path == '/login' || sessionStorage.getItem("token")) {
        next();
    } else {
        setTimeout(() => {
            next({
                path: '/login'
            });
        })


    }
})