import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import layout from '../layout/layout.vue'

Vue.use(VueRouter)


const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: layout,
        meta: {
            title: "首页"
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue')
            },
            {
                path: "/login",
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
            },
            {
                path: "/main-page",
                name: 'main-page',
                component: () => import(/* webpackChunkName: "main-page" */ "@/views/main-page/index.vue"),
            },
            {
                path: "/player-settings",
                name: 'player-settings',
                component: () => import(/* webpackChunkName: "player-settings" */ "@/views/player-settings/index.vue"),
            },
            {
                path: "/lottery-settings",
                name: 'lottery-settings',
                component: () => import(/* webpackChunkName: "lottery-settings" */ "@/views/lottery-settings/index.vue"),
            },
            {
                path: "/data-statistics",
                name: 'data-statistics',
                component: () => import(/* webpackChunkName: "data-statistics" */ "@/views/data-statistics/index.vue"),
            }
        ]
    },


]

const router = new VueRouter({
    routes
})

export default router
