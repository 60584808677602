import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import "@/styles/index.scss";
import "@/permission";
import * as echarts from "echarts";
import * as api from "@/api";
import environment from "./config/config"

Vue.prototype.$http = api;
Vue.prototype.$environment = environment;
Vue.prototype.$echarts = echarts
Vue.prototype.$bus = new Vue()
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
