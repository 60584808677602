import axios from "axios";
import { Message, MessageBox } from "element-ui";
// import { UserModule } from "@/store/modules/user";
// import Base from './Base'
import Qs from "qs";
import environment from "../config/config"
// import { getHeads } from "@/config/constant"

let showToast: boolean = true; // 控制post请求下是否弹toast提示(默认为true)

const service = axios.create({
    baseURL: environment.env,
    timeout: 50000,
    headers: { Authorization: sessionStorage.getItem("token") },
    transformRequest: [
        (data) => {
            // FormData数据类型不需要转化
            if (Object.prototype.toString.call(data) == '[object FormData]') {
                return data;
            } else {
                return Qs.stringify(data);
            }
        }
    ]
});

service.interceptors.request.use(
    (config: any) => {
        // config.headers = { Authorization: sessionStorage.getItem("token") }
        config.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            ...{ Authorization: sessionStorage.getItem("token") },

        };

        if (config.method == "post") {
            // UserModule.SendAua({ sign: "+" + config.url, params: config.data })
        }

        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// service.interceptors.response.use(
//     response => {
//         const res = response.data;
//         const method = response.config.method

//         if (res.code !== 1000) {
//             if (res.code && res.code > 1000) {
//                 if (method == 'post' && showToast) {
//                     Message({
//                         message: res.msg || 'Error',
//                         type: 'error',
//                         duration: 2000
//                     })
//                 }

//                 if (res.code === 1003) {
//                     MessageBox.confirm(
//                         '你已被登出，请重新登录！',
//                         {
//                             confirmButtonText: '确定',
//                             type: 'warning'
//                         }
//                     ).then(() => {
//                         // UserModule.ResetToken()
//                         location.reload()
//                     })
//                 }

//                 return response.data;
//             } else {
//                 Message({
//                     message: '服务器异常',
//                     type: 'error',
//                     duration: 2000
//                 })

//                 return {
//                     code: 1030,
//                     msg: "服务器异常"
//                 }
//             }
//         } else {
//             return response.data;
//         }
//     },
//     error => {
//         Message({
//             message: "网络异常",
//             type: 'error',
//             duration: 2000
//         })

//         return {
//             code: 1030,
//             msg: "网络异常！",
//         };
//     }
// );

const request = function (data: any) {
    if (data.showToast === false) {
        showToast = false;
        delete data.showToast
    } else {
        showToast = true;
    }

    return service(data)
}

export default request;
