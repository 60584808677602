import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=b3681aa6&scoped=true&"
import script from "./layout.vue?vue&type=script&lang=ts&"
export * from "./layout.vue?vue&type=script&lang=ts&"
import style0 from "./layout.vue?vue&type=style&index=0&id=b3681aa6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_ptonlk6we4655ftgzox6ddameq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3681aa6",
  null
  
)

export default component.exports