
import { Component, Vue } from "vue-property-decorator";
import { HeadBar, sliderBar, AppMain } from "./components";

@Component({
    components: {
        HeadBar,
        sliderBar,
        AppMain,
    },
})
export default class layout extends Vue {}
