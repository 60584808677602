
let env = "";
let address = "";
if (process.env.NODE_ENV === 'development') {
    env = "http://test.api.vote.zhishengyigou.com";
    address = "http://test.m.vote.zhishengyigou.com"
} else if (process.env.NODE_ENV === 'production') {
    env = "https://api.vote.zhishengyigou.com"
    address = "http://m.vote.zhishengyigou.com"
}

let environment = {
    env,
    address,
}

export default environment;