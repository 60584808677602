
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class HeadBar extends Vue {
    private dialogFormVisible = false;
    private form: any = {
        oldpwd: "",
        newpwd: "",
    };

    LogOut() {
        sessionStorage.removeItem("token");
        this.$router.push({
            name: "login",
        });
        this.$message({
            message: "退出登录成功",
            type: "success",
        });
    }

    changePassword() {
        this.dialogFormVisible = true;
    }

    async defineCipher() {
        let res = await (this as any).$http.getPassword({
            oldpwd: this.form.oldpwd,
            newpwd: this.form.newpwd,
        });
        if (res.data.code == "0047") {
            this.$message({
                message: "修改成功请重新登录",
                type: "success",
            });
            sessionStorage.removeItem("token");
            this.$router.push({
                name: "login",
            });
        } else {
            this.$message({
                message: res.msg,
                type: "error",
            });
        }
    }
}
