import request from "./request";

// 获取验证码
export const getCaptcha = (params?: any) =>
    request({
        url: "/api/captcha",
        method: "get",
        params
    })

// 获取奖品列表
export const GetPrizeList = (data: any) =>
    request({
        url: "/basis/authMenu/add",
        method: "post",
        data
    })

// 添加权限菜单
export const addPermissionMenu = (data: any) =>
    request({
        url: "/basis/authMenu/add",
        method: "post",
        data
    })

// 投票设置
export const IntroductionToVoting = (data: any) =>
    request({
        url: "/api/vote/vote_ste",
        method: "post",
        data
    })

// 上传图片
export const uploadImg = (data: any) =>
    request({
        url: "/api/user/upload_action_pic",
        method: "post",
        data
    })


// 上传图片
export const shopList = (data: any) =>
    request({
        url: "/api/vote/get_vote_user_list",
        method: "post",
        data
    })

// 保存选手设置
export const preserveShop = (data: any) =>
    request({
        url: "/api/vote/vote_user_ste",
        method: "post",
        data
    })

// 编辑奖品列表
export const prizeList = (data: any) =>
    request({
        url: "/api/vote/set_activity",
        method: "post",
        data
    })

// 基本设置
export const getLottery = (data: any) =>
    request({
        url: "/api/vote/get_activity",
        method: "post",
        data
    })

/* 获取奖项设置 */
export const addPrizeList = (data: any) =>
    request({
        url: "/api/vote/get_goods_list",
        method: "post",
        data
    })

/* 保存奖品设置 */
export const addPrizeListGoods = (data: any) =>
    request({
        url: "/api/vote/add_prize_goods",
        method: "post",
        data
    })

/* 获取数据统计 */
export const voteInit = (data: any) =>
    request({
        url: "/api/vote/vote_init",
        method: "post",
        data
    })

/* 获取投票设置信息 */
export const gainVotesList = (data: any) =>
    request({
        url: "/api/vote/vote_get",
        method: "post",
        data
    })

/* 获取邀请链接 */
export const gitInvitationLink = (data: any) =>
    request({
        url: "/api/user/getmyinvitercode",
        method: "post",
        data
    })

// 登录账号
export const logininterface = (data: any) =>
    request({
        url: "/api/login",
        method: "post",
        data
    })

// 登录账号
export const getPassword = (data: any) =>
    request({
        url: "/api/user/edit_user_password",
        method: "post",
        data
    })


